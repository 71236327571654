import { useQuery } from "@tanstack/react-query";
import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import { PaginatedResults } from "admin/src/ui/types/common/general-types";
import { PaginationRequest } from "admin/src/ui/types/common/general-types";
import { FiltersRequest } from "admin/src/utils/helpers/filter-where-clause";
import { ErrorMessage, FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ReactNode } from "react";
import Select, { MultiValue, PropsValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

export type NewAppSelectOption = {
  label: string | ReactNode;
  value?: string | number;
  disabled?: boolean;
};

type NewAppSelectPaginationFunc<T> = (
  filters?: FiltersRequest,
  pagination?: PaginationRequest,
) => Promise<PaginatedResults<T> | T[]>;

export type NewAppSelectMenuProps<T> = {
  name: string;
  valueProperty?: string;
  displayProperty?: string;
  selectOptionQuery?: NewAppSelectPaginationFunc<T>;
  placeholder?: string;
  initialOption?: PropsValue<NewAppSelectOption>;
  localOptionsList?: NewAppSelectOption[];
  searchable?: boolean;
  label?: string;
  onChange?: (newValue: string | number | (string | number)[]) => void;
  disabled?: boolean;
  additionalClasses?: string;
  labelClassName?: string;
  customMappingFunction?: (
    options: PaginatedResults<T> | T,
  ) => NewAppSelectOption;
  testid?: string;
  multiple?: boolean;
  createNewSelectOptionQuery?: (query: string) => Promise<T>;
  allowNewOptions?: boolean;
  menuClassName?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NewAppSelectMenu = <T extends { [key: string]: any }>({
  name,
  valueProperty,
  displayProperty,
  selectOptionQuery,
  placeholder,
  label,
  testid,
  localOptionsList,
  initialOption,
  disabled,
  searchable,
  multiple,
  labelClassName,
  additionalClasses,
  createNewSelectOptionQuery,
  customMappingFunction,
  menuClassName,
  allowNewOptions,
  onChange,
  ...props
}: NewAppSelectMenuProps<T>) => {
  if (allowNewOptions && !createNewSelectOptionQuery) {
    throw new Error(
      "You can't create new options without providing an API request to create it on server",
    );
  }

  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);

  const selectOptions = useQuery(
    [multiple ? "comboOptions" : "selectOptions", name],
    () => {
      return selectOptionQuery?.();
    },
    {
      enabled: !!selectOptionQuery,
      select: (responseData): NewAppSelectOption[] => {
        if (!responseData) {
          return [];
        }

        const unwrappedResponseData =
          responseData instanceof Array ? responseData : responseData.results;

        if (customMappingFunction) {
          return unwrappedResponseData.map((value) =>
            customMappingFunction(value),
          );
        }
        return unwrappedResponseData.map((option: T) => ({
          label:
            option[
              displayProperty ??
                (typeof responseData === "object" ? "label" : "display")
            ],
          value: option[valueProperty ?? "value"],
        }));
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const options = selectOptions.data?.length
    ? selectOptions.data
    : localOptionsList || [];

  if (
    (selectOptions.isLoading && !!selectOptionQuery) ||
    (!selectOptions.isSuccess && !!selectOptionQuery)
  ) {
    return <Spinner spinnerSize={SpinnerSize.Small} />;
  }

  const commonSelectProps = {
    id: name,
    defaultValue:
      options.find((option) => option.value === value) ?? initialOption,
    name: name,
    styles: {
      control: () => ({}),
    },
    // All styles and classNames can be adjusted, so we can add more props with additionalClassNames when we want to
    // It seems that these classNames can not take our custom classes, that's why control classes is so long
    classNames: {
      control: () =>
        "flex rounded text-sm bg-neutral-light text-neutral-mid-700 border border-neutral-mid-200 focus:border-neutral-mid-300 focus:ring-neutral-mid-300 disabled:bg-neutral-light-550 disabled:text-neutral-light-900",
      menu: () =>
        `${name}-options !bg-neutral-light !shadow-lg ${menuClassName}`,
      option: () => `${name}-option`,
    },
    options,
    onChange: (
      newValue:
        | MultiValue<NewAppSelectOption>
        | SingleValue<NewAppSelectOption>,
    ) => {
      const multiValue: NewAppSelectOption[] | undefined =
        Array.isArray(newValue) && newValue.length ? newValue : undefined;

      const singleValue =
        !Array.isArray(newValue) && (newValue as NewAppSelectOption)?.value
          ? (newValue as NewAppSelectOption)
          : undefined;

      if (!multiValue && !singleValue) {
        return;
      }

      if (singleValue) {
        setFieldValue(name, singleValue.value);
        if (onChange) {
          onChange(singleValue.value || "");
        }
      } else {
        const onlyValues = multiValue?.map((option) => option?.value) ?? [];
        const values = onlyValues.filter(
          (value): value is string | number => value !== undefined,
        );

        setFieldValue(name, values);

        if (!values.every((value) => !!value)) {
          return;
        }

        if (onChange) {
          onChange(values);
        }
      }
    },
    isOptionDisabled: (option: NewAppSelectOption) => {
      return !!option.disabled;
    },
    placeholder,
    isSearchable: searchable,
    isDisabled: disabled,
    isMulti: multiple,
  };

  return (
    <div data-testid={testid || name} className={`${additionalClasses}`}>
      {label && (
        <label
          htmlFor={name}
          className={labelClassName}
          hidden={!label}
        >{`${label}:`}</label>
      )}
      {allowNewOptions ? (
        <CreatableSelect
          onCreateOption={(inputValue) =>
            createNewSelectOptionQuery?.(inputValue)
          }
          {...commonSelectProps}
          {...props}
        />
      ) : (
        <Select {...commonSelectProps} {...props} />
      )}
      <ErrorMessage
        component="span"
        className="text-danger-small"
        name={name}
      />
    </div>
  );
};

export default NewAppSelectMenu;
