import { Prisma } from "@prisma/client";
import { hslToHex } from "admin/src/utils/helpers/color-helpers";
import { LuxonDateFormats } from "admin/src/utils/helpers/datetime/dateFormats";
import { formatUsdString } from "admin/src/utils/helpers/invoice/formatUsdString";
import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";
import { getFormattedMembershipData } from "admin/src/utils/helpers/profile/getFormattedMembershipData";
import { renderTemplateUtil } from "admin/src/utils/template/renderTemplateUtil";
import Handlebars from "handlebars";
import { DateTime } from "luxon";
import {
  InvoiceDto,
  InvoiceView,
  mapInvoiceDtoToInvoiceView,
} from "shared/mappers/database/accounting/invoice/invoice";
import { AddressView } from "shared/mappers/database/address";
import {
  mapProfileDtoToProfileView,
  ProfileDto,
  ProfileView,
} from "shared/mappers/database/profile/profile";
import {
  mapSocietyDtoToSocietyView,
  SocietyDto,
  SocietyView,
} from "shared/mappers/database/society/society";
import { SocietyTheme } from "shared/mappers/database/society/theme";
import {
  mapSubmissionInstanceDtoToSubmissionInstanceView,
  SubmissionInstanceDto,
  SubmissionInstanceView,
} from "shared/mappers/database/submission/instance/submission-instance";

type HandlebarsTemplateHelper = {
  name: string;
  helper: Handlebars.HelperDelegate;
};

type RegisterHandleBarHelpersProps = {
  timeZone: string;
};

export const registerHandleBarHelpers = ({
  timeZone = "America/Chicago",
}: RegisterHandleBarHelpersProps) => {
  const helpersRaw: HandlebarsTemplateHelper[] = [
    {
      name: "greaterThan",
      helper: function (arg1, arg2, options) {
        return arg1 > arg2 ? options.fn(this) : options.inverse(this);
      },
    },
    {
      name: "ifEqualString",
      helper: function (arg1, arg2, options) {
        if (arg1 === arg2) {
          return options.fn(this); // Render the block
        } else {
          return options.inverse(this); // Render the else block
        }
      },
    },
    {
      name: "formatUsdString",
      helper: (text: string) => formatUsdString(Number(text)),
    },
    {
      name: "formatDate",
      helper: (iso: string) =>
        DateTime.fromISO(iso).setZone(timeZone).toFormat(LuxonDateFormats.D),
    },
    {
      name: "formatAddress",
      helper: (address: AddressView) => {
        if (!address) return "";
        let addressString = "";
        if (address.line1) {
          addressString += "<div>" + address.line1 + "</div>";
        }
        if (address.line2) {
          addressString += "<div>" + address.line2 + "</div>";
        }
        if (address.line3) {
          addressString += "<div>" + address.line3 + "</div>";
        }
        if (address.city || address.state || address.postalCode) {
          addressString += "<div>";
        }
        if (address.city) {
          addressString += address.city;
        }
        if (address.city && address.state) {
          addressString += ", ";
        }
        if (address.state) {
          addressString += address.state;
        }
        if ((address.state || address.city) && address.postalCode) {
          addressString += " ";
        }
        if (address.postalCode) {
          addressString += address.postalCode;
        }
        if (address.city || address.state || address.postalCode) {
          addressString += "</div>";
        }
        return addressString;
      },
    },
    {
      name: "formattedMembershipData",
      helper: (profile: ProfileView) =>
        JSON.stringify(getFormattedMembershipData(profile)),
    },
    {
      name: "themeToHex",
      helper: (
        societyTheme: SocietyTheme[],
        hueModify?: string,
        saturationModify?: string,
        lightnessModify?: string,
      ) => hslToHex(societyTheme, hueModify, saturationModify, lightnessModify),
    },
    {
      name: "isNotLastFiltered",
      helper: function (currentIndex, items, property, value) {
        const filtered = items.filter((item: any) => item[property] === value);
        return currentIndex < filtered.length - 1;
      },
    },
  ];
  helpersRaw.forEach(({ name, helper }) => {
    Handlebars.registerHelper(name, helper);
  });
};
export type StandardTemplateVariables = {
  society?: SocietyView;
  profile?: ProfileView;
  displayName?: string;
  invoice?: InvoiceView;
  submissionInstance?: SubmissionInstanceView;
  tokenUrl?: string;
  message?: string;
  logo?: string;
  additionalTemplateVariables?: Prisma.InputJsonValue;
  invoiceReceiptHtml?: string;
};
export type GetStandardTemplateVariablesUtilProps = {
  society?: SocietyDto;
  profile?: ProfileDto;
  invoice?: InvoiceDto;
  submissionInstance?: SubmissionInstanceDto;
  tokenUrl?: string;
  message?: string;
  logoBase64?: string;
  logoUrl?: string;
  additionalTemplateVariables?: Prisma.InputJsonValue;
  invoiceReceiptHtmlTemplate?: string;
  timeZone?: string;
};
export const getStandardTemplateVariablesUtil = ({
  profile,
  society,
  invoice,
  submissionInstance,
  tokenUrl,
  message,
  logoBase64,
  logoUrl,
  additionalTemplateVariables,
  invoiceReceiptHtmlTemplate,
  timeZone = "America/Chicago",
}: GetStandardTemplateVariablesUtilProps) => {
  registerHandleBarHelpers({ timeZone });
  const standardVariables: StandardTemplateVariables = {
    society: society ? mapSocietyDtoToSocietyView(society) : undefined,
    profile: profile ? mapProfileDtoToProfileView(profile) : undefined,
    displayName: profile ? getDisplayNameHelper(profile) : undefined,
    invoice: invoice ? mapInvoiceDtoToInvoiceView(invoice) : undefined,
    submissionInstance: submissionInstance
      ? mapSubmissionInstanceDtoToSubmissionInstanceView(submissionInstance)
      : undefined,
    tokenUrl,
    message,
    logo: logoBase64 || logoUrl,
    additionalTemplateVariables,
  };
  if (invoiceReceiptHtmlTemplate && invoice) {
    standardVariables.invoiceReceiptHtml = renderTemplateUtil({
      template: invoiceReceiptHtmlTemplate,
      templateData: standardVariables,
    });
  }
  return standardVariables;
};
